import React from "react"
import styles from './Footer.module.css'
import footerLogo from './footer-logo.svg'

export const Footer: React.FC = () => {
  return <div className={styles.footer}>
    <div className={styles.logoContactContainer}>
      <div className={styles.logoContainer}>
        <img src={footerLogo} className={styles.logo}/>
      </div>
      <div className={styles.contactContainer}>
        <div className={styles.contactBlock}>
          <h2 className={styles.contactHeader}>Contact</h2>
          <dl className={styles.contactItems}>
            <dt>Phone</dt>
            <dd><a href="tel:+442088630042">+44 (0)208 863 0042</a></dd>
            <dt>Email</dt>
            <dd><a href="mailto:info@adcampbell.co.uk">info@adcampbell.co.uk</a></dd>
            <dt>Instagram</dt>
            <dd><a href="https://instagram.com/adc_construction">@adc_construction</a></dd>
            <dt>LinkedIn</dt>
            <dd><a href="https://www.linkedin.com/company/ad-campbell/about/">ad-campbell</a></dd>
          </dl>
        </div>
      </div>
    </div>
    <div className={styles.legal}>
      AD Campbell is the trading name used by ADC Building Services Ltd.
    </div>
  </div>
}