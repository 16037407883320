import React from "react"
import { Header } from "../components/Header/Header";
import { Picture } from "../components/Picture/Picture";
import { Layout } from "../components/Layout/Layout";
import { Carousel } from "../components/Carousel/Carousel";
import { Block } from "../components/Block/Block";
import { P } from "../components/P/P";
import { UL } from "../components/UL/UL";
import { LI } from "../components/LI/LI";
import { Footer } from "../components/Footer/Footer";
import Helmet from "react-helmet";

const Index: React.FunctionComponent = () => (
  <Layout>
    <Helmet title="AD Campbell" />
    <Header />
    <Carousel timeout={6000}>
        <Picture filename="home/slide1.jpg" fadeIn={false} critical={true} key={1}/>
        <Picture filename="home/slide2.jpg" fadeIn={false} critical={true} key={2}/>
        <Picture filename="home/slide3.jpg" fadeIn={false} critical={true} key={3}/>
        <Picture filename="home/slide4.jpg" fadeIn={false} critical={true} key={4}/>
        <Picture filename="home/slide5.jpg" fadeIn={false} critical={true} key={5}/>
        <Picture filename="home/slide6.jpg" fadeIn={false} critical={true} key={6}/>
    </Carousel>
    <Block>
        <P>Established in 1986 by Albert Campbell, AD Campbell is a family run construction company specialised in executing home extension and refurbishment works to a high finish.</P>
        <P>Working closely with a trusted network of consultants, AD Campbell offers the following design led construction solutions to customers in London and the surrounding Home Counties.</P>
        <UL>
            <LI>Loft Conversions</LI>
            <LI>Rear/Side Extensions</LI>
            <LI>Full House Refurbishments</LI>
            <LI>Kitchens</LI>
            <LI>Bathrooms</LI>
            <LI>Landscaping</LI>
        </UL>
        <P>Get in touch if you would like to discuss how AD Campbell can help realise your homes potential.</P>
    </Block>
    <Footer />
  </Layout>
);

export default Index
