import React from "react";
import GImg, { GatsbyImageProps } from 'gatsby-image'
import { graphql, StaticQuery } from "gatsby";

export interface IImageProps extends Omit<GatsbyImageProps, "sizes"> {
  filename: string;
  alt?: string;
  className?: string;
}

export const Picture: React.FunctionComponent<IImageProps> =
  (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 2880, quality: 90) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.filename);
      });

      if (!image) { return null; }

      const imageSizes = image.node.childImageSharp.sizes;
      return (
        <GImg
          sizes={imageSizes}
          {...props}
        />
      );
    }}
  />
)
