import React from "react";
import styles from './Header.module.css';
import headerLogo from './header-logo.svg';

type Icon =  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>;

const PhoneIcon: React.FC<Icon> = (props) => {
  return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="phone" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path fill="currentColor"
          d="M476.5 22.9L382.3 1.2c-21.6-5-43.6 6.2-52.3 26.6l-43.5 101.5c-8 18.6-2.6 40.6 13.1 53.4l40 32.7C311 267.8 267.8 311 215.4 339.5l-32.7-40c-12.8-15.7-34.8-21.1-53.4-13.1L27.7 329.9c-20.4 8.7-31.5 30.7-26.6 52.3l21.7 94.2c4.8 20.9 23.2 35.5 44.6 35.5C312.3 512 512 313.7 512 67.5c0-21.4-14.6-39.8-35.5-44.6zM69.3 464l-20.9-90.7 98.2-42.1 55.7 68.1c98.8-46.4 150.6-98 197-197l-68.1-55.7 42.1-98.2L464 69.3C463 286.9 286.9 463 69.3 464z"
          className="" />
  </svg>);
};

const MailIcon: React.FC<Icon> = (props) => {
  return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path fill="currentColor"
          d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
          className="" />
  </svg>)
}

export const Header: React.FunctionComponent = () => {
  return <header className={styles.header}>
    <div className={styles.logoContainer}>
      <img src={headerLogo} className={styles.logo} />
    </div>
    <div className={styles.contactContainer}>
      <div className={styles.contactItem}>
        <MailIcon className={styles.icon}/>
        <a href="">info@adcampbell.co.uk</a>
      </div>
      <div className={styles.contactItem}>
        <PhoneIcon className={styles.icon}/>
        <a href="">+44 (0)208 863 0042</a>
      </div>
    </div>
  </header>
}