import React, { useEffect, useState } from "react"
import styles from './Carousel.module.css';

interface ICarouselProps {
  children: any[];
  timeout: number;
}

export const Carousel: React.FC<ICarouselProps> = ({ children, timeout = 5000 }) => {

  const [count, setCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCount((count + 1) % children.length);
    }, timeout);
  }, [count]);

  console.log('curr', count);

  return <>
    {
      children.map((item, idx) => {
        const className = (idx !== count) ? styles.hidden: '';
        return React.cloneElement(item, { className });
      })
    }
  </>
}